import axios from 'axios'
import { defineStore } from 'pinia'
import { ref, computed, shallowRef } from 'vue'
import i18n from '@/plugins/i18n'
import Api from '@/engage/plugins/api'
import { SnackbarOptionsToken, useSnackbarStore } from '@/engage/stores/snackbar'
import { useWorklogStore } from '@/engage/stores/worklog'
import { useHelpRequestStore } from '@/engage/stores/helpRequests'
import type { ConnectionState } from '@twilio/conversations'
import type { Sounds } from '@/engage/types/sounds'
import type { TwillioUser } from '@/engage/types/chat'

type SupportedConnectionState = Exclude<ConnectionState, 'unknown' | 'error'>
interface ReleaseCaseAction {
  caseId: number
  comment?: string
}
interface ReassignCaseAction {
  caseId: number
  comment?: string
  userId: number
}
interface TransferCaseAction {
  caseId: number
  comment?: string
  userId: number
  skillId: number
  reasonCode?: string
}
interface EndConversationCaseAction {
  caseId: number
  channelSid: string
}

export type SupportedConnectionStateObject = {
  [K in SupportedConnectionState]: K
}

export const CONNECTION_STATE: SupportedConnectionStateObject = {
  connecting: 'connecting',
  connected: 'connected',
  disconnecting: 'disconnecting',
  disconnected: 'disconnected',
  denied: 'denied',
} as const

export const useChatStore = defineStore('chat', () => {
  const client = shallowRef()
  const status = ref<SupportedConnectionState>(CONNECTION_STATE.disconnected)
  const user = ref('')
  const typingInProgress = ref<string | null>(null)
  const typingInProgressActive = ref(false)
  const maxReconnectionAttemp = ref(5)
  const reconnectionAttemp = ref(0)
  const playSound = ref<string | null>(null)
  const unansweredTicketId = ref<number[]>([])
  const connectionAlertTimeout = ref<ReturnType<typeof setTimeout> | null>(null)
  const messagesAreAllRead = ref(true)

  const helpRequestStore = useHelpRequestStore()

  // Getters
  const isBrowserTabTitleActive = computed(() => {
    return !!unansweredTicketId.value.length
  })

  // Actions
  const updateTypingMessage = (payload: string | null) => {
    typingInProgress.value = payload?.length ? payload : null
  }
  const updateTypingActive = (payload = false) => {
    typingInProgressActive.value = payload
  }
  const setPlaySound = (payload: Sounds) => {
    playSound.value = payload
  }
  const pushUnansweredTicketId = (ticketId: number | null = null) => {
    if (ticketId !== null && !~unansweredTicketId.value.findIndex((id) => id === ticketId)) unansweredTicketId.value.push(ticketId)
  }
  const removeUnansweredTicketId = (ticketId: number | null = null) => {
    const index = unansweredTicketId.value.findIndex((id) => id === ticketId)
    if (ticketId !== null && ~index) {
      unansweredTicketId.value.splice(index, 1)
    }
  }
  const CHAT_connectionStateChanged = (state: ConnectionState) => {
    const snackbarStore = useSnackbarStore()

    snackbarStore.closeSnackbar()

    if (Object.keys(CONNECTION_STATE).includes(state)) {
      status.value = state as SupportedConnectionState
    }

    let snackbarConfig: SnackbarOptionsToken | undefined

    switch (state) {
      case CONNECTION_STATE.connecting:
        if (reconnectionAttemp.value) {
          snackbarConfig = {
            text: i18n.global.t('api.engage.twilio.logout.warning') as string,
            color: 'warning50',
            timeout: -1,
          }
        }
        break

      case CONNECTION_STATE.connected:
        if (reconnectionAttemp.value) {
          snackbarConfig = {
            text: i18n.global.t('api.engage.twilio.login.success') as string,
            color: 'success50',
            timeout: 10000,
          }
          reconnectionAttemp.value = 0
        }
        break

      case CONNECTION_STATE.disconnected:
      case CONNECTION_STATE.disconnecting:
      case CONNECTION_STATE.denied:
        snackbarConfig = {
          text: i18n.global.t('api.engage.twilio.login.failure') as string,
          color: 'error50',
          timeout: -1,
        }
        break

      default:
        break
    }

    /*
     * INFO: required timeout to avoid disconnection messages shown during refresh
     */
    if (typeof snackbarConfig !== 'undefined') {
      _setConnectionAlert(setTimeout(() => snackbarStore.showSnackbar(snackbarConfig!), snackbarConfig.timeout === -1 ? 1000 : 0))
    }
  }
  const CHAT_userSubscribed = (payload: TwillioUser) => {
    user.value = payload.state.identity
  }
  const CHAT_connectionError = () => {
    const snackbarStore = useSnackbarStore()
    _increaseReconnectionAttemps()
    if (reconnectionAttemp.value === maxReconnectionAttemp.value) {
      snackbarStore.showSnackbar({
        text: i18n.global.t('api.engage.twilio.login.failure') as string,
        color: 'error50',
        timeout: 0,
      })
    }
  }
  const resetTyping = () => {
    updateTypingMessage(null)
    updateTypingActive(false)
  }
  const reassignConversation = async ({ comment, userId, caseId }: ReassignCaseAction) => {
    const snackbarStore = useSnackbarStore()
    const worklogStore = useWorklogStore()
    try {
      // @ts-ignore
      await Api.chatConversation.reassign({
        comment,
        userId,
      })
      worklogStore.saveAndRemoveWorkLog()
      helpRequestStore.deleteHelpRequest({
        caseId,
      })
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.data?.message) {
        snackbarStore.showSnackbar({
          text: error.response.data.message,
          color: 'warning50',
        })
      }
      return error
    }
  }
  const releaseConversation = async ({ comment, caseId }: ReleaseCaseAction) => {
    const snackbarStore = useSnackbarStore()
    const worklogStore = useWorklogStore()
    try {
      // @ts-ignore
      await Api.chatConversation.release({
        comment,
      })
      worklogStore.saveAndRemoveWorkLog()
      helpRequestStore.deleteHelpRequest({ caseId })
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.data?.message) {
        snackbarStore.showSnackbar({
          text: error?.response.data.message,
          color: 'warning50',
        })
      }
      return error
    }
  }
  const transferConversation = async ({ skillId, reasonCode, comment, userId, caseId }: TransferCaseAction) => {
    const snackbarStore = useSnackbarStore()
    const worklogStore = useWorklogStore()
    try {
      // @ts-ignore
      await Api.chatConversation.transfer({
        skillId,
        reasonCode,
        comment,
        userId,
      })
      worklogStore.saveAndRemoveWorkLog()
      helpRequestStore.deleteHelpRequest({ caseId })
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.data?.message) {
        snackbarStore.showSnackbar({
          text: error.response.data.message,
          color: 'warning50',
        })
        return error
      }
    }
  }
  const endConversation = async ({ channelSid, caseId }: EndConversationCaseAction) => {
    const snackbarStore = useSnackbarStore()
    try {
      await Api.chat.deleteChat(channelSid)
      helpRequestStore.deleteHelpRequest({ caseId })
    } catch (error) {
      snackbarStore.showSnackbar({
        text: i18n.global.t('api.engage.errors.undefined') as string,
        color: 'warning50',
      })

      return error
    }
  }

  /**
   * Initialize Twilio websocket connection
   * And set $socket as reactive data
   */
  const connect = async () => {
    const eventMap = new Map<string, (arg: any) => void>([
      ['connectionError', CHAT_connectionError],
      ['connectionStateChanged', CHAT_connectionStateChanged],
      ['userSubscribed', CHAT_userSubscribed],
    ])
    const VueTwilioChat = (await import('@/engage/plugins/vueTwilioChat')).default
    client.value = await VueTwilioChat.create(eventMap)
  }

  // internal
  const _increaseReconnectionAttemps = () => {
    reconnectionAttemp.value++
  }
  const _setConnectionAlert = (timeout: ReturnType<typeof setTimeout>) => {
    if (connectionAlertTimeout.value) clearTimeout(connectionAlertTimeout.value)
    connectionAlertTimeout.value = timeout
  }

  return {
    status,
    user,
    typingInProgress,
    typingInProgressActive,
    maxReconnectionAttemp,
    reconnectionAttemp,
    playSound,
    unansweredTicketId,
    connectionAlertTimeout,
    isBrowserTabTitleActive,
    client,
    setPlaySound,
    CHAT_connectionStateChanged,
    CHAT_userSubscribed,
    CHAT_connectionError,
    resetTyping,
    reassignConversation,
    releaseConversation,
    transferConversation,
    endConversation,
    updateTypingMessage,
    updateTypingActive,
    pushUnansweredTicketId,
    removeUnansweredTicketId,
    connect,
    messagesAreAllRead,
  }
})
