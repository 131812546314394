import { createRouter, createWebHistory, RouteLocationNormalized, START_LOCATION } from 'vue-router'
import configResolver from '@/common/helpers/configResolver'
import { AuthoritiesEnum } from '@/common/types/session'
import { useSessionStore } from '@/common/stores/sessionStore'
import { useEntityStore } from '@/common/stores/entityStore'
import { useMenuStore } from '@/admin/stores/menuStore'
import { CHANGE_PASSWORD_ROUTE, ERROR_ROUTE_NAME, NOT_FOUND_PATH, OIDC_LANDING_ROUTE_NAME, routes } from '@/router/routes'
import { getMenuByLink, userHaveAccessToMenu } from '@/admin/components/menu/menuTraverser'
import { setTranslations, addTranslations } from '@/common/services/LocalizationService'
import { clear as clearLocalData } from '@/common/services/LocalStorageService'
import { useBreaksStore } from '@/agent/stores/breakStore'
import { useSearchStore } from '@/search/stores/searchStore'
import { PrefixTranslationsEnum } from '@/common/types/i18n'
import { displayMessage } from '@/common/services/NotificationDisplayService'
import { SessionError } from '@/common/types/SessionError'
import { getUserLandingPage } from '@/common/services/UsersService'
import { keycloakAuthentication, triggerOidcChangePassword } from '@/common/services/AuthenticationService'
import { useQuasar } from 'quasar'
import { extractErrorMessage } from '@/common/helpers/errorUtils'
import { ADMIN_SETTINGS_KB_ROUTE } from '@/admin/routes'
import { isDisabledFeatureView } from '@/common/services/FeatureFlagService'
import { usePreBreakStore } from '@/agent/stores/preBreakStore'
import { beforeEach as engageBeforeEach, afterEach as engageAfterEach } from '@/engage/router/index'
import { sanitizeUrl } from '@braintree/sanitize-url'

const router = createRouter({
  history: createWebHistory(configResolver.pathname),
  routes,
})

router.beforeEach(engageBeforeEach)
router.beforeResolve(async (to, from) => {
  // Do nothing before this test
  if ((to?.meta?.requiresAuth ?? true) === false) {
    return true
  }

  if ((to?.meta?.requiresSession ?? true) === false) {
    await keycloakAuthentication(false)
    return true
  }

  const quasar = useQuasar()
  const sessionStore = useSessionStore()
  if (from === START_LOCATION && !sessionStore.id) {
    try {
      quasar.loading.show()
      await sessionStore.initSession()
      await setTranslations(sessionStore.locale, ['assist'])
    } catch (error) {
      if (error instanceof SessionError) {
        return { name: ERROR_ROUTE_NAME }
      } else await displayMessage(String(error), 'danger')
    } finally {
      quasar.loading.hide()
    }
  }

  if (to?.path === CHANGE_PASSWORD_ROUTE) {
    await handleChangePasswordRoute(to, from, sessionStore.oidcEnabled)
  }

  if (to?.name === OIDC_LANDING_ROUTE_NAME) {
    if (sessionStore.oidcEnabled) {
      try {
        quasar.loading.show()
        const { landingPagePath } = await getUserLandingPage()
        const urlPattern = /^(http|https):/
        if (urlPattern.test(landingPagePath)) {
          window.location.href = sanitizeUrl(landingPagePath)
        } else {
          return { path: landingPagePath }
        }
      } catch (error) {
        return { name: ERROR_ROUTE_NAME, query: { errorMessage: extractErrorMessage(error) } }
      } finally {
        quasar.loading.hide()
      }
    } else {
      return { name: ERROR_ROUTE_NAME }
    }
  }

  if (to?.path === ADMIN_SETTINGS_KB_ROUTE) return handleKnowledgeBaseRoute()

  if (to?.meta?.admin) {
    if (to.name === 'admin-root') {
      if (sessionStore.getAuthoritySet.has(AuthoritiesEnum.ADMIN_USERS)) {
        return { path: '/assist/admin/users/' }
      }
      const menuStore = useMenuStore()
      const firstDisplayedMenuLink = menuStore.firstDisplayedMenuLink
      if (!firstDisplayedMenuLink) return { path: NOT_FOUND_PATH }
      return { path: firstDisplayedMenuLink }
    }

    if (!to?.meta?.technical) {
      const menuByLink = getMenuByLink(to.fullPath)
      if (!menuByLink) {
        return { path: NOT_FOUND_PATH }
      }

      if (from === START_LOCATION) {
        if (!userHaveAccessToMenu(sessionStore.authorities, menuByLink)) return { path: NOT_FOUND_PATH }
      }
    }

    // Refresh stored data
    if (from === START_LOCATION || to?.meta?.invalidate || (from?.meta?.legacy && !to?.meta?.legacy)) {
      const entityStore = useEntityStore()
      await entityStore.fetchData()
    }
  } else {
    if (from === START_LOCATION || from?.meta?.admin || from?.meta?.engage || to?.meta.engage) {
      clearLocalData(['session', 'parameters'])

      if (from?.meta?.admin) {
        sessionStore.loadSessionSettings()

        const searchStore = useSearchStore()
        searchStore.$reset()
      }
      const breakStore = useBreaksStore()
      const preBreakStore = usePreBreakStore()
      await breakStore.load()
      await preBreakStore.load()

      await addTranslations(sessionStore.locale, [
        PrefixTranslationsEnum.USERSTATE,
        PrefixTranslationsEnum.SCREEN,
        PrefixTranslationsEnum.SKILL,
        PrefixTranslationsEnum.SPACE,
        PrefixTranslationsEnum.REASON,
      ])
    }
  }
})
router.afterEach(engageAfterEach)

async function handleChangePasswordRoute(to: RouteLocationNormalized, from: RouteLocationNormalized, oidcEnabled: boolean) {
  if (oidcEnabled) {
    const redirectUri = `${configResolver.root}${from.path}`
    await triggerOidcChangePassword(redirectUri)
  }
}

function handleKnowledgeBaseRoute() {
  if (isDisabledFeatureView(ADMIN_SETTINGS_KB_ROUTE)) return { path: NOT_FOUND_PATH }
}

export default router
