import { AxiosRequestConfig } from 'axios'
import HttpService from '@/common/services/HttpService'
import { getScreenById } from '@/common/services/ScreenService'
import { Screen, FieldScreenRelation } from '@/common/types/screen'
import { getParameterByCode } from '@/common/services/InstanceParameterService'
import { UnprotectedParameterEnum, InstanceParameter } from '@/common/types/instanceParameter'
import { listAllFields, getName, getShortName } from '@/common/services/FieldService'
import { getCases } from '@/common/services/CaseService'
import { getCustomers } from '@/common/services/CustomerService'
import { getInteractions } from '@/common/services/InteractionService'
import { type Interaction, InteractionIncludeEnum } from '@/common/types/interaction'
import { getSearchConfig } from '@/admin/services/SearchService'
import { EntityEnum, NULLUUID } from '@/common/types/general'
import { FieldData, FieldTypeEnum, EntityFieldCodeEnum, FieldContextEnum } from '@/common/types/fields'
import { ColumnDefinition, ResultViewConfig } from '@/search/types/resultViewConfig'
import { Case, CaseIncludeEnum } from '@/common/types/case'
import {
  OperationEnum,
  type SearchFieldWrapper,
  type Criteria,
  type SearchRequest,
  FieldsPlacementEnum,
  fieldsPlacementObject,
  EntityFieldsDefaultOperationMap,
  FieldTypesDefaultOperationMap,
  SpecialFieldCriteria,
  FieldsUsedByGroupedCriteriaMap,
  GroupedCriteriaParentEnum,
  GroupedCriteriaParentEntityEnumMap,
  ExportEndpointEnum,
  type SearchFilter,
  type EntitySearchable,
} from '@/search/types/search'
import { getComponent } from '@/search/components/fields/search/componentMap'
import { AuthoritiesEnum } from '@/common/types/session'
import { useSessionStore } from '@/common/stores/sessionStore'
import { Notify } from 'quasar'
import i18n from '@/plugins/i18n'

const { t } = i18n.global

/**
 * Get search fields from configuration did in administration, we get only codes
 * Get all fields from context search with details
 * Filter fields from configuration to get fields to display with all details we need
 * @returns fields to display
 */
const getFields = async (): Promise<FieldData[]> => {
  const sessionStore = useSessionStore()
  if (!sessionStore.searchConfigs.length) return []

  const userSearchConfigs = sessionStore.searchConfigs[0]

  try {
    const [searchConfig, allFields] = await Promise.all([getSearchConfig(userSearchConfigs), listAllFields(FieldContextEnum.SEARCH)])

    const fields = new Set(searchConfig.fields.map((obj) => obj.code))
    return allFields.filter((field: FieldData) => fields.has(field.code))
  } catch (error) {
    console.log(error)
  }

  return []
}

/**
 * For each field retrieve from server, we create a SearchFieldWrapper
 * and store that in maps "criteria" or "groupedCriteria" with the code as key
 * @returns map of SearchFieldWrapper with the code as key
 */
export const createCriteria = async () => {
  const criteria: Criteria = {}
  const groupedCriteria: Criteria = {}
  const fields: FieldData[] = await getFields()
  fields.forEach((field: FieldData) => {
    if (!FieldsUsedByGroupedCriteriaMap.has(field.code)) {
      // Normal criteria
      createNormalCriteria(field, criteria)
    } else {
      // Grouped criteria
      createGroupedCriteria(field, criteria, groupedCriteria)
    }
  })
  return [criteria, groupedCriteria]
}

const createNormalCriteria = (field: FieldData, criteria: Criteria) => {
  const component = getComponent(field.code, field.type)
  if (component) {
    const operation = getDefaultOperation(field.code, field.type)
    const fieldWrapper: SearchFieldWrapper = {
      field: { ...field, name: getName(field) },
      component: component,
      defaultValue: [],
      currentValue: [],
      defaultOperation: operation,
      operation: operation,
    }
    criteria[field.code] = fieldWrapper
  }
}

const createGroupedCriteria = (field: FieldData, criteria: Criteria, groupedCriteria: Criteria) => {
  const groupedCriteriaParent = FieldsUsedByGroupedCriteriaMap.get(field.code)
  if (!criteria[groupedCriteriaParent as string]) {
    // insert grouped criteria parent
    const component = getComponent(groupedCriteriaParent as string, null)
    if (component) {
      const fieldWrapper: SearchFieldWrapper = {
        field: {
          code: groupedCriteriaParent as string,
          name: '',
          entity: GroupedCriteriaParentEntityEnumMap.get(groupedCriteriaParent as GroupedCriteriaParentEnum) as EntityEnum,
          entityProperty: null,
          modelProperty: null,
          type: FieldTypeEnum.TEXT, // Fake type
          custom: false,
          context: [FieldContextEnum.SEARCH],
        },
        component: component,
        defaultValue: [],
        currentValue: [],
        defaultOperation: OperationEnum.custom,
        operation: OperationEnum.custom,
      }
      criteria[groupedCriteriaParent as string] = fieldWrapper
    }
  }
  // insert grouped criteria
  const component = getComponent(field.code, field.type)
  if (component) {
    const fieldWrapper: SearchFieldWrapper = {
      field: { ...field, name: getName(field) },
      component: component,
      defaultValue: [],
      currentValue: [],
      defaultOperation: OperationEnum.custom,
      operation: OperationEnum.custom,
    }
    groupedCriteria[field.code] = fieldWrapper
  }
}

/**
 * get TICKET_SEARCH_MAX_RESULTS parameter
 * @returns InstanceParameter
 */
export const getMaxResultParameter = async (): Promise<InstanceParameter> => {
  return getParameterByCode(UnprotectedParameterEnum.TICKET_SEARCH_MAX_RESULTS)
}

/**
 * Return operation
 * @param code entity field code
 * @param type field type
 * @returns operation
 */
const getDefaultOperation = (code: string, type: FieldTypeEnum): OperationEnum => {
  let operation: OperationEnum | undefined = EntityFieldsDefaultOperationMap.get(code)

  if (operation) return operation

  operation = FieldTypesDefaultOperationMap.get(type)

  if (!operation) {
    return OperationEnum.eq
  }

  return operation
}

/**
 * Create object to ask server what we want to search with fields filled
 * @param entitySelected Entities to search
 * @param fieldWrapperList Fields list
 * @returns Search request object
 */
const createSearchRequest = (
  entitySelected: EntityEnum,
  fieldWrapperList: SearchFieldWrapper[],
  sort: string[],
  maxResult: number,
): SearchRequest => {
  return {
    entity: entitySelected,
    max: maxResult,
    fields: fieldWrapperList
      .filter((fieldWrapper) => fieldWrapper.currentValue.length > 0 && !SpecialFieldCriteria.includes(fieldWrapper.field.code))
      .map((fieldWrapper) => {
        return {
          code: fieldWrapper.field.code,
          op: fieldWrapper.operation,
          values: fieldWrapper.currentValue,
        }
      }),
    criteria: fieldWrapperList
      .filter((fieldWrapper) => fieldWrapper.currentValue.length > 0 && SpecialFieldCriteria.includes(fieldWrapper.field.code))
      .map((fieldWrapper) => {
        return {
          code: fieldWrapper.field.code,
          op: fieldWrapper.operation,
          values: isGroupedCriteriaParent(fieldWrapper.field.code) ? [JSON.parse(fieldWrapper.currentValue[0])] : fieldWrapper.currentValue,
        }
      }),
    sort: sort,
  }
}

/**
 * Call server to get search result ids. We should get a number array
 * @param searchRequest
 * @returns A number array of entity ids
 */
const launchSearchServer = async (searchRequest: SearchRequest): Promise<number[]> => {
  return await HttpService.postData<number[]>('/search/api/v1/specs', searchRequest)
}

/**
 * Call server to get screen corresponding to resultViewConfig for an entity
 * Call server to get all fields
 * Transform all to a ResultViewConfig
 * @param entitySelected The entity table we want to display
 * @returns ResultViewConfig interface
 */
export const getResultViewConfigToDisplay = async (entitySelected: EntityEnum): Promise<ResultViewConfig> => {
  const sessionStore = useSessionStore()
  let screenId: number = 0
  if (entitySelected === EntityEnum.TICKET) screenId = sessionStore.screenIdTicketList
  else if (entitySelected === EntityEnum.CUSTOMER) screenId = sessionStore.screenIdClientList
  else if (entitySelected === EntityEnum.INTERACTION) screenId = sessionStore.screenIdInteractionList
  const [screen, fields] = await Promise.all([getScreenById(screenId), listAllFields()])
  const resultViewConfig: ResultViewConfig = createResultViewConfig(screen, fields)
  return resultViewConfig
}

/**
 * - Create the search request
 * - Call server to get search result ids
 * @param entitySelected Entities to search
 * @param fieldWrapperList Fields list
 * @returns Array of entities ids
 */
export const fetchSearchResultIds = async (
  entitySelected: EntityEnum,
  fieldWrapperList: SearchFieldWrapper[],
  sort: string[],
  maxResult: number,
): Promise<number[]> => {
  try {
    const searchRequest: SearchRequest = createSearchRequest(entitySelected, fieldWrapperList, sort, maxResult)
    const resultIds: number[] = await launchSearchServer(searchRequest)
    return resultIds
  } catch (error) {
    Notify.create({
      type: 'negative',
      message: t('assist.search.error'),
    })
    console.error(error)
    return []
  }
}

/**
 * Fetch one entity to get details
 * @param entitySelected Entity to fetch
 * @param id entity id
 * @param resultViewConfig listing configuration
 * @returns Promise for one entity
 */
const fetchEntities = async (entitySelected: EntityEnum, ids: number[], resultViewConfig: ResultViewConfig): Promise<Array<EntitySearchable>> => {
  if (entitySelected === EntityEnum.TICKET) {
    return fetchCases(ids, resultViewConfig)
  } else if (entitySelected === EntityEnum.CUSTOMER) {
    return getCustomers(ids)
  } else if (entitySelected === EntityEnum.INTERACTION) {
    return fetchInteractions(ids, resultViewConfig)
  }
  return []
}

const fetchCases = async (ids: number[], resultViewConfig: ResultViewConfig): Promise<Array<Case>> => {
  const include: CaseIncludeEnum[] = []
  const hasHasAttachmentsField = resultViewConfig.columns.find(
    (c) => c.entity === EntityEnum.TICKET && c.code === EntityFieldCodeEnum.HAS_ATTACHMENTS,
  )

  if (hasHasAttachmentsField) {
    include.push(CaseIncludeEnum.HAS_ATTACHMENTS)
  }

  const cases: Case[] = await getCases(ids, include)

  const customerCases = cases.reduce((accumulator, caseData) => {
    if (caseData.customerId) {
      if (accumulator.has(caseData.customerId)) accumulator.get(caseData.customerId)?.push(caseData)
      else accumulator.set(caseData.customerId, [caseData])
    }
    return accumulator
  }, new Map<number, Case[]>())

  if (customerCases.size) {
    const hasCustomerField = resultViewConfig.columns.find((c) => c.entity === EntityEnum.CUSTOMER && c.code !== EntityFieldCodeEnum.CUSTOMER_ID)
    const customers = hasCustomerField ? await getCustomers([...customerCases.keys()]) : [...customerCases.keys()].map((id) => ({ id }))

    customers.forEach((customer) => {
      const caseList = customerCases.get(customer.id) ?? []
      caseList.forEach((c) => {
        c.customer = customer
      })
    })
  }
  return cases
}

const fetchInteractions = async (ids: number[], resultViewConfig: ResultViewConfig): Promise<Array<Interaction>> => {
  const include: InteractionIncludeEnum[] = []
  const hasHasAttachmentsField = resultViewConfig.columns.find(
    (c) => c.entity === EntityEnum.INTERACTION && c.code === EntityFieldCodeEnum.INTERACTION_HAS_ATTACHMENTS,
  )

  if (hasHasAttachmentsField) {
    include.push(InteractionIncludeEnum.HAS_ATTACHMENTS)
  }

  const interactions: Interaction[] = await getInteractions(undefined, ids, 0, ids.length, include)

  const caseInteractions = interactions.reduce((accumulator, interactionData) => {
    if (interactionData.caseId) {
      if (accumulator.has(interactionData.caseId)) accumulator.get(interactionData.caseId)?.push(interactionData)
      else accumulator.set(interactionData.caseId, [interactionData])
    }
    return accumulator
  }, new Map<number, Interaction[]>())

  if (caseInteractions.size) {
    const hasCaseField = resultViewConfig.columns.find((c) => c.entity === EntityEnum.TICKET && c.code !== EntityFieldCodeEnum.TICKET_ID)
    const cases = hasCaseField ? await getCases([...caseInteractions.keys()]) : [...caseInteractions.keys()].map((id) => ({ id }))

    cases.forEach((caseI) => {
      const interactionList = caseInteractions.get(caseI.id) ?? []
      interactionList.forEach((i) => {
        i.case = caseI as Case
      })
    })
  }

  return interactions
}

/**
 * Fetch entities one by one to get details for each of them
 * @param entitySelected Entity to fetch
 * @param resultIds Array of entities ids
 * @param resultViewConfig listing configuration
 * @returns Array of entities objects
 */
export const fetchResultDetails = async (
  entitySelected: EntityEnum,
  resultIds: number[],
  resultViewConfig: ResultViewConfig,
): Promise<Array<EntitySearchable>> => {
  try {
    if (resultIds.length === 0) return []
    const result: Array<EntitySearchable> = await fetchEntities(entitySelected, resultIds, resultViewConfig)
    return result
  } catch (error) {
    Notify.create({
      type: 'negative',
      message: t('assist.search.error.fetchentities'),
    })
    console.error(error)
    return []
  }
}

/**
 * Initialise entities options available to search
 * @returns Options array
 */
export const getEntitiesOptions = (): Array<{ label: string; value: EntityEnum }> => {
  const searchAuthorities = new Map([
    [AuthoritiesEnum.TICKETS_SEARCH, { label: 'assist.entity.cases', entity: EntityEnum.TICKET }],
    [AuthoritiesEnum.CUSTOMERS_SEARCH, { label: 'assist.entity.customers', entity: EntityEnum.CUSTOMER }],
    [AuthoritiesEnum.SEARCH_INTERACTION, { label: 'assist.entity.interactions', entity: EntityEnum.INTERACTION }],
  ])
  const entitiesOptions: Array<{ label: string; value: EntityEnum }> = []
  const sessionStore = useSessionStore()
  for (const [authority, options] of searchAuthorities) {
    if (sessionStore.getAuthoritySet.has(authority)) {
      entitiesOptions.push({ label: t(options.label), value: options.entity })
    }
  }
  return entitiesOptions
}

/**
 * Filter array of SearchFieldWrapper depends of entity and placement
 * @param entity Entity to filter
 * @param fieldPlacement Placement of the fields
 * @param fieldWrapperList Fields list
 * @returns Fields list for left or right placement
 */
export const getFieldWrapperListForPlacement = (
  entity: EntityEnum,
  fieldPlacement: FieldsPlacementEnum,
  fieldWrapperList: SearchFieldWrapper[],
): SearchFieldWrapper[] => {
  // Native fields
  const nativeFieldsCodesArray: string[] = fieldsPlacementObject[entity][fieldPlacement]
  const nativeFieldsArrayFiltered = fieldWrapperList
    .filter((fieldWrapper) => nativeFieldsCodesArray.includes(fieldWrapper.field.code))
    .sort((a, b) => nativeFieldsCodesArray.indexOf(a.field.code) - nativeFieldsCodesArray.indexOf(b.field.code))
  // Custom fields
  const customFieldsArray = fieldWrapperList.filter((fieldWrapper) => fieldWrapper.field.entity === entity && fieldWrapper.field.custom)
  const indexToSplit: number = Math.floor(customFieldsArray.length / 2)
  let customFieldsArrayFiltered: SearchFieldWrapper[] = []
  if (fieldPlacement === FieldsPlacementEnum.LEFT) {
    customFieldsArrayFiltered = customFieldsArray.slice(0, indexToSplit + 1)
  } else if (fieldPlacement === FieldsPlacementEnum.RIGHT) {
    customFieldsArrayFiltered = customFieldsArray.slice(indexToSplit + 1)
  }
  return [...nativeFieldsArrayFiltered, ...customFieldsArrayFiltered]
}

/**
 * Sort fields list to have the same order than the map "fieldsPlacementObject" for native fields.
 * Custom fields are added at the end without sorting.
 * @param entity Entity to filter
 * @param fieldWrapperList Fields list
 * @returns Fields list sorted
 */
export const sortFieldsByEntity = (entity: EntityEnum, fieldWrapperList: SearchFieldWrapper[]): SearchFieldWrapper[] => {
  const nativeFieldsCodesArray: string[] = fieldsPlacementObject[entity][FieldsPlacementEnum.LEFT]
    .concat(fieldsPlacementObject[entity][FieldsPlacementEnum.RIGHT])
    .concat(fieldsPlacementObject[entity][FieldsPlacementEnum.GROUP])
  return fieldWrapperList
    .filter((fieldWrapper) => nativeFieldsCodesArray.includes(fieldWrapper.field.code))
    .sort((a, b) => nativeFieldsCodesArray.indexOf(a.field.code) - nativeFieldsCodesArray.indexOf(b.field.code))
    .concat(fieldWrapperList.filter((fieldWrapper) => fieldWrapper.field.entity === entity && fieldWrapper.field.custom))
}

export function paginate(array: number[], page: number, rowsPerPage: number) {
  return array.slice((page - 1) * rowsPerPage, page * rowsPerPage)
}

export function isGroupedCriteriaParent(code: string): boolean {
  return (<any>Object).values(GroupedCriteriaParentEnum).includes(code)
}

/**
 * Call server to perform a sync export
 * @param exportEndpoint name of export endpoint
 * @param ids list of entities ids to export
 * @param definitionId id of export template definition
 * @returns CSV text of search export in with given template
 */

export const postSyncExport = async (
  exportEndpoint: ExportEndpointEnum,
  ids: number[],
  exportId: number,
  config: AxiosRequestConfig = {},
): Promise<string> => {
  return await HttpService.postData<string>(`/api/v1/${exportEndpoint}/export`, { ids, exportId }, config)
}

/**
 * Call server to perform an async export
 * @param exportEndpoint name of export endpoint
 * @param ids list of entities ids to export
 * @param definitionId id of export template definition
 * @returns export uuid
 */
export const postAsyncExport = async (
  exportEndpoint: ExportEndpointEnum,
  ids: number[],
  exportId: number,
  config: AxiosRequestConfig = {},
): Promise<UUID> => {
  return await HttpService.postData<UUID>(`/api/v1/${exportEndpoint}/exportAsync`, { ids, exportId }, config)
}

/**
 * Call server to perform an async export interactions
 * @param ids list of interactions ids to export
 * @param exportDefinitionCode code of export definition
 * @returns export uuid
 */
export const postExportInteractions = async (ids: number[], exportDefinitionCode: UUID, config: AxiosRequestConfig = {}): Promise<UUID> => {
  return await HttpService.postData<UUID>(`/api/v2/interactions/export`, { ids, exportDefinitionCode }, config)
}

/**
 * post search filter
 * @param searchConfigId search config id
 * @param data filter data
 */
export const createSearchFilter = async (searchConfigId: UUID, data: SearchFilter): Promise<SearchFilter> => {
  return HttpService.postData('/search/api/v1/config/' + searchConfigId + '/filters', data)
}

/**
 * delete search filter
 * @param searchFilterId search filter id
 */
export const deleteSearchFilter = async (searchFilterId: UUID): Promise<void> => {
  return await HttpService.deleteData('/search/api/v1/config/filters/' + searchFilterId)
}

/**
 * Get object to save a new filter
 * @param entitySelected Entities to search
 * @param fieldWrapperList Fields list
 * @param fieldWrapperListInGroupedCriteria Fields in grouped criteria list
 * @returns Search filter object
 */
export const getSearchFilterToCreate = (
  name: string,
  entitySelected: EntityEnum,
  shared: boolean,
  fieldWrapperList: SearchFieldWrapper[],
  fieldWrapperListInGroupedCriteria: SearchFieldWrapper[],
): SearchFilter => {
  return {
    id: NULLUUID,
    name,
    entity: entitySelected,
    shared,
    creationUser: '',
    fields: [...fieldWrapperList, ...fieldWrapperListInGroupedCriteria]
      .filter((fieldWrapper) => fieldWrapper.currentValue.length > 0 && !isGroupedCriteriaParent(fieldWrapper.field.code))
      .map((fieldWrapper) => {
        return {
          code: fieldWrapper.field.code,
          op: fieldWrapper.operation,
          values: fieldWrapper.currentValue,
        }
      }),
  }
}

/**
 * Call server to get list of saved search filters
 * @returns array of saved search filters
 */

export const getSavedFilters = async (): Promise<SearchFilter[]> => {
  const sessionStore = useSessionStore()
  if (!sessionStore.searchConfigs.length) return []

  try {
    const searchConfigId: string = sessionStore.searchConfigs[0]
    const savedFilters = await HttpService.getData<SearchFilter[]>(`/search/api/v1/config/${searchConfigId}/filters`)
    return savedFilters
  } catch (error) {
    console.error(error)
    return []
  }
}

/**
 * Create ResultViewConfig interface used to display a table.
 * It merges the screen fields with the native and custom fields
 * @param screen Screen from server
 * @param fields fields from server
 * @returns ResultViewConfig interface
 */
export const createResultViewConfig = (screen: Screen, fields: FieldData[]): ResultViewConfig => {
  const resultViewConfig: ResultViewConfig = {
    screenId: screen.id,
    sortBy: null,
    sortSens: null,
    columns: [],
  }
  const columns: ColumnDefinition[] = []
  screen.fieldScreenRelations.forEach(function (relation: FieldScreenRelation) {
    const field: FieldData | undefined = fields.find((f) => {
      if (relation.entityField) return f.code === relation.entityField
      if (relation.customField) return f.code === relation.customField
      return null
    })
    if (field?.modelProperty) {
      columns.push({
        code: field.code,
        property: field.modelProperty,
        sortable: fieldSortable(field),
        alwaysVisible: relation.alwaysVisible,
        label: getShortName(field),
        entity: field.entity,
        type: field.type,
      })
      if (relation.defaultSortDir !== undefined) {
        resultViewConfig.sortBy = field.code
        resultViewConfig.sortSens = relation.defaultSortDir
      }
    }
  })
  resultViewConfig.columns = columns
  return resultViewConfig
}

const fieldSortable = (field: FieldData): boolean => {
  return field.code !== EntityFieldCodeEnum.HAS_ATTACHMENTS && field.code !== EntityFieldCodeEnum.INTERACTION_HAS_ATTACHMENTS
}
